<template>
    <div>
        <AvisoArmazenamento :percentual_uso="armazenamento.percentual_uso"></AvisoArmazenamento>
        <MetricasCadastros :metricas="metricas"/>
        <PedidosTransmitidos :metricas="metricas" />
    </div>
</template>
<script>
  import {get} from '../../helpers/apiRequest';
  import Charts from "../../components/charts/Charts";
  import MetricasCadastros from "./Metricas/MetricasCadastros";
  import PedidosTransmitidos from "./Metricas/PedidosTransmitidos";
  import AvisoArmazenamento from './Metricas/AvisoArmazenamento';


  export default {
    name: 'home',
    components: { PedidosTransmitidos, MetricasCadastros, Charts, AvisoArmazenamento},
    data() {
      return {
        loading: false,
        dashboards: [],
        armazenamento: {
          'percentual_uso': null
        },
        metricas: {
          'qtd_usuarios' : null,
          'qtd_clientes' : null,
          'qtd_produtos' : null,
          'qtd_pedidos'  : null,
          'pedidos_transmitidos': {
            'titulo'    : '',
            'metricas'  : []
          },
        },
        colaborador: {
          nome: ''
        },
      };
      
    },
    created(){
          this.armazenamento.percentual_uso = this.$route.params.percentual_uso;
          this.loadArmazenamento(this.armazenamento.percentual_uso);
    },
    methods: {
      loadMetricas() {
        get('/admin/metricas-home')
          .then((json) => {
            this.loading = false;
            this.metricas = json;
          })
          .catch((err) => {
            this.loading = false;
          });
      },
      loadArmazenamento(){
        get('/admin/info-servidor')
        .then((json) => {
            this.loading = false;
            this.armazenamento = json;
          })
          .catch((err) => {
            this.loading = false;
          });
      }
      // loadDashboards() {
      //   get('/admin/dashboards')
      //     .then((json) => {
      //       this.loading = false;
      //       this.dashboards = json;
      //     })
      //     .catch((err) => {
      //       this.loading = false;
      //     });
      // },
    },
    beforeMount() {
      //Sempre uma função com contato na api antes de tudo, a apiRequest válida o token do usuário.
      this.loadMetricas();
      const {colaborador} = (JSON.parse(localStorage.getItem("app")));
      this.colaborador = colaborador;
    },
  };
</script>
