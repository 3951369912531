<template>
    <div class="card">
        <header class="card-header">
            {{ this.metricas.pedidos_transmitidos.titulo }}
        </header>
        <div class="card-body">
            <CChartLine
                style="height:300px"
                :datasets="datasets"
                :labels="labels"
                :options="options"
            />
                <!--:options="computedOptions"-->
        </div>
    </div>
</template>

<script>
  import { CChartLine } from '@coreui/vue-chartjs'

  export default {
    name: "PedidosTransmitidos",
    components: {
      CChartLine
    },
    props: [
      'metricas'
    ],
    computed: {
      labels() {
        return this.metricas.pedidos_transmitidos.metricas.reduce((acc, metrica) => {
          acc.push( metrica.data_formatada );
          return acc;
        }, []);
      },
      datasets() {
        return [
          this.getMetrica('Pedidos B2B', 'qtd_pedidos_b2b', '#39f'),
          this.getMetrica('Pedidos CallCenter', 'qtd_pedidos_callcenter', '#f9b115'),
          this.getMetrica('Pedidos Força de Vendas', 'qtd_pedidos_forca_vendas', '#004576'),
        ]
      },
      options() {
        return {
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              display: true
            }],
              yAxes: [{
              display: true
            }]
          },
          elements: {
            line: {
              borderWidth: 2
            },
            point: {
              radius: 4,
                hitRadius: 10,
                hoverRadius: 4
            }
          }
        };
      }
    },
    methods: {
      getMetrica(titulo, campo, cor, tensao) {
        return {
          label: titulo,
          data: this.metricas.pedidos_transmitidos.metricas.reduce((acc, metrica) => {
            acc.push( metrica[campo] );
            return acc;
          }, []),
          fill: true,
          borderColor: cor,
          tension: 0.1
        };
      }
    }
  }
</script>