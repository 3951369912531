<template>

    <div class="row">
        <div class="col-sm-3">
            <div class="card bg-primary text-white">
                <div class="card-body d-flex justify-content-between">
                    <div>
                        <div class="text-value-lg">{{ metricas.qtd_usuarios }}</div>
                        <div>Usuários Ativos</div>
                    </div>
                    <div>
                        <fa-icon icon="user" size="2x" />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-3">
            <div class="card bg-info text-white">
                <div class="card-body d-flex justify-content-between">
                    <div>
                        <div class="text-value-lg">{{ metricas.qtd_clientes }}</div>
                        <div>Clientes Ativos</div>
                    </div>
                    <div>
                        <fa-icon icon="users" size="2x" />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-3">
            <div class="card bg-warning text-white">
                <div class="card-body d-flex justify-content-between">
                    <div>
                        <div class="text-value-lg">{{ metricas.qtd_produtos }}</div>
                        <div>Produtos Ativos</div>
                    </div>
                    <div>
                        <fa-icon icon="shopping-basket" size="2x" />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-3">
            <div class="card bg-danger text-white" title="Pedidos já transmitidos">
                <div class="card-body d-flex justify-content-between">
                    <div>
                        <div class="text-value-lg">{{ metricas.qtd_pedidos }}</div>
                        <div>Pedidos já transm.</div>
                    </div>
                    <div>
                        <fa-icon icon="file-invoice-dollar" size="2x" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: "MetricasCadastros",
    props: [
      'metricas'
    ],
  }
</script>

<style scoped>

</style>